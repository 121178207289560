/**
 * User: Miroslav
 * Date: 18/10/2018
 * Time: 15:27
 * Taken from: https://github.com/philipstanislaus/performant-array-to-tree
 */

if (typeof window !== 'undefined' && !window.hasOwnProperty('uArray')) {
    window['uArray'] = {
        /**
         * Checks if an array contains a specific item.
         * @param {array} haystack The array to check for.
         * @param {*} needle The item to search in the haystack.
         * @param {boolean} strict If true, a [===] checking will be performed, if false, a [String(item) === String(needle)] checking will be performed.
         * @return {boolean}
         */
        contains: function (haystack, needle, strict) {
            if (!haystack || !Array.isArray(haystack) || haystack.length === 0 || typeof needle === 'undefined')
                return false;
            if (strict) {
                for (let item of haystack) {
                    if (item === needle) return true;
                }
            } else {
                needle = needle === null ? null : String(needle);
                for (let item of haystack) {
                    if (String(item) === needle) return true;
                }
            }
            return false;
        },
        /**
         * Gets the key/position for a specific item.
         * @param {array} haystack The array to check for.
         * @param {*} needle The item to search in the haystack.
         * @param {boolean} strict If true, a [===] checking will be performed, if false, a [String(item) === String(needle)] checking will be performed.
         * @return {null|int} Null if the needle has not been found, otherwise, the int indicating the position of the needle.
         */
        indexOf: function (haystack, needle, strict) {
            if (!haystack || !Array.isArray(haystack) || haystack.length === 0 || typeof needle === 'undefined')
                return null;
            let iterator = haystack.entries();
            let entry;
            if (strict) {
                while (!(entry = iterator.next()).done) {
                    if (entry.value[1] === needle) return entry.value[0];
                }
            } else {
                needle = needle === null ? null : String(needle);
                while (!(entry = iterator.next()).done) {
                    if (String(entry.value[1]) === needle) return entry.value[0];
                }
            }
            return null;
        },
        /**
         * Resulting array will have each items data nested in a property called data. This avoids any collision because
         * an additional property (children) is added for each item.
         * @param items
         * @param primaryIdName
         * @param parentIdName
         * @return {Array}
         */
        arrayToTreeNestedData: function (items, primaryIdName, parentIdName) {
            if (!items || !Array.isArray(items) || items.length === 0 || !primaryIdName || !parentIdName)
                return [];
            // the resulting unflattened tree
            var rootItems = [];
            // stores all already processed items with ther ids as key so we can easily look them up
            var lookup = {};
            // idea of this loop:
            // whenever an item has a parent, but the parent is not yet in the lookup object, we store a preliminary parent
            // in the lookup object and fill it with the data of the parent later
            // if an item has no parentId, add it as a root element to rootItems
            for (var _i = 0, items_1 = items; _i < items_1.length; _i++) {
                var item = items_1[_i];
                var itemId = item[primaryIdName];
                var parentId = item[parentIdName];
                // look whether item already exists in the lookup table
                if (!Object.prototype.hasOwnProperty.call(lookup, itemId)) {
                    // item is not yet there, so add a preliminary item (its data will be added later)
                    lookup[itemId] = { data: null, children: [] };
                }
                // add the current item's data to the item in the lookup table
                lookup[itemId].data = item;
                var TreeItem = lookup[itemId];
                if (parentId === null) {
                    // is a root item
                    rootItems.push(TreeItem);
                }
                else {
                    // has a parent
                    // look whether the parent already exists in the lookup table
                    if (!Object.prototype.hasOwnProperty.call(lookup, parentId)) {
                        // parent is not yet there, so add a preliminary parent (its data will be added later)
                        lookup[parentId] = { data: null, children: [] };
                    }
                    // add the current item to the parent
                    lookup[parentId].children.push(TreeItem);
                }
            }
            return rootItems;
        },
        /**
         * Resulting array will have each items data put directly. This can cause collisions because an additional
         * property (children) is added for each item. Please only pass items without such property!
         * @param items
         * @param primaryIdName
         * @param parentIdName
         * @return {Array}
         */
        arrayToTreePlainData: function (items, primaryIdName, parentIdName) {
            if (!items || !Array.isArray(items) || items.length === 0 || !primaryIdName || !parentIdName)
                return [];
            // the resulting unflattened tree
            var rootItems = [];
            // stores all already processed items with ther ids as key so we can easily look them up
            var lookup = {};
            // idea of this loop:
            // whenever an item has a parent, but the parent is not yet in the lookup object, we store a preliminary parent
            // in the lookup object and fill it with the data of the parent later
            // if an item has no parentId, add it as a root element to rootItems
            for (var _i = 0, items_1 = items; _i < items_1.length; _i++) {
                var item = items_1[_i];
                var itemId = item[primaryIdName];
                var parentId = item[parentIdName];
                // look whether item already exists in the lookup table
                if (!Object.prototype.hasOwnProperty.call(lookup, itemId)) {
                    // item is not yet there, so add a preliminary item (its data will be added later)
                    lookup[itemId] = { children: [] };
                }
                // add the current item's data to the item in the lookup table
                Object.assign(lookup[itemId], item);
                var TreeItem = lookup[itemId];
                if (parentId === null) {
                    // is a root item
                    rootItems.push(TreeItem);
                }
                else {
                    // has a parent
                    // look whether the parent already exists in the lookup table
                    if (!Object.prototype.hasOwnProperty.call(lookup, parentId)) {
                        // parent is not yet there, so add a preliminary parent (its data will be added later)
                        lookup[parentId] = { children: [] };
                    }
                    // add the current item to the parent
                    lookup[parentId].children.push(TreeItem);
                }
            }
            return rootItems;
        }
    };
}